import loadable from "@loadable/component";
import React from "react";

const TopNavigationProxy = loadable(() => import("../top-navigation"));

const TopNavigation = ({ data }) => {
	return <TopNavigationProxy data={data} />;
};

export default TopNavigation;
