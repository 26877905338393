import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import React, { useContext } from "react";
import tw, { styled, css } from "twin.macro";
// import TopNavigation from "../components/top-navigation";
import TopNavigation from "../components/dynamic-components/top-navigation";
import { Link } from "gatsby-plugin-intl";
import { ThemeContext } from "./themes/theme-context";
import { motion } from "framer-motion";

const Wrapper = styled.div(({ headerGradient }) => [
	tw`w-full fixed top-0 left-0 z-20 min-w-screen`,
	headerGradient,
	css`
		@media (max-height: 600px) {
			position: relative;
		}
	`,
]);

const Container = tw.div`max-w-screen-xl mx-auto`;

const StyledHeader = tw.header`flex flex-col lg:flex-row text-center justify-center lg:items-center m-auto w-full lg:px-5 lg:py-4`;

const VibrantLink = tw.div`font-serif text-sm md:text-lg md:leading-serif-lg lg:text-xl leading-serif-base lg:leading-serif-xl lg:font-medium-heavy py-1.5 lg:w-1/2 lg:text-left lg:py-0 lg:pl-8 relative lg:bg-none`;

const StyledLink = styled(Link)`
	${tw`w-full relative flex items-center justify-center  lg:items-start lg:justify-start`}
`;

const StyledBackButton = tw.div`absolute left-4 lg:relative lg:left-0 lg:mr-10`;

const PageTitleLink = ({ pageTitle }) => {
	return (
		<StyledLink to="/">
			<StyledBackButton>
				<FontAwesomeIcon icon={faAngleLeft} />
			</StyledBackButton>
			{pageTitle}
		</StyledLink>
	);
};

const Header = ({ data, pageTitle }) => {
	const node = data.nodes[0];

	const { theme } = useContext(ThemeContext);

	const fadeAnimation = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<Wrapper headerGradient={theme.headerGradient}>
			<Container>
				<StyledHeader>
					<VibrantLink>
						<motion.div
							initial="hidden"
							animate="visible"
							variants={fadeAnimation}
							transition={{ duration: 0.8 }}
						>
							{pageTitle ? (
								<PageTitleLink pageTitle={pageTitle} />
							) : (
								<a href={node.headerLink}>{node.siteTitle}</a>
							)}
						</motion.div>
					</VibrantLink>
					<TopNavigation data={node} />
				</StyledHeader>
			</Container>
		</Wrapper>
	);
};

export const headerFragment = graphql`
	fragment header on ContentfulHeader {
		siteTitle
		headerLink
		phoneNumber {
			label
			link
			title
		}
		chatLink {
			title
			label
			link
		}
	}
`;

export default Header;
